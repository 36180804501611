import { createStore, applyMiddleware  } from 'redux';
import rootReducer from './index';
//import {loadState, saveState} from './localStorage';
import {auth} from './middleware/auth';
import {data} from './middleware/data';
import {masquerade} from './middleware/masquerade';
import {stats} from './middleware/stats';
import {notification} from './middleware/notification';
import {recovery} from './middleware/recovery';
import {registration} from './middleware/registration';
import { setItem, getItem } from './indexedDB';

async function loadState() {
  //console.log('load state');
  const savedState = await getItem('reduxState');
  // console.log('savedState', savedState)
  // if ( ! savedState) {
  //   console.log('no state');
  //   await setItem('reduxState',{});
  // }
  return savedState || undefined; // Return saved state or undefined if not found
}


async function initStore() {
 
  const persistedState = await loadState(); 

  const middlewareEnhancer = applyMiddleware(auth, data, masquerade, stats, notification, recovery, registration)

  const store = createStore(rootReducer , persistedState,  middlewareEnhancer );

  store.subscribe( async () => {
    await setItem('reduxState', store.getState());
  });

  return store;
}

export default initStore;

