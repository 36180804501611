// indexedDB.js
import { openDB } from 'idb';

const DB_NAME = 'ReduxStore';
const STORE_NAME = 'stateStore';

export async function setItem(key, value) {
  const db = await openDB(DB_NAME, 2, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });

  return db.put(STORE_NAME, value, key);
}

export async function getItem(key) {
  const db = await openDB(DB_NAME, 2, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });

  return db.get(STORE_NAME, key);
}

export async function deleteItem(key) {
  const db = await openDB(DB_NAME, 1);
  return db.delete(STORE_NAME, key);
}

export async function clearStore() {
  const db = await openDB(DB_NAME, 1);
  return db.clear(STORE_NAME);
}